<template lang="pug">
.profile
  .text-h5.font-weight-bold.primary--text.mb-6 Min bedrift
  v-card.card-simple.rounded-xl.mb-8
    v-card-text.pa-8
      v-text-field(
        v-model="name"
        label="Bedriftsnavn"
        append-icon="mdi-tag-text"
        rounded filled autofocus
      )
      .text-subtitle-2.mb-8
        b.primary--text.pr-2 Bedrift opprettet:
        .d-inline.secondary--text {{ formatDate(company.created_at) }}
      v-btn(
        :disabled="name === '' || loading"
        :loading="loading"
        @click="doUpdateCompanyName"
        color="secondary"
        x-large block depressed rounded
      ) Lagre

  template(v-if="false")
    .text-h5.font-weight-bold.primary--text.mb-6 Lås filtre
    .text-subtitle-2.mt-2.mb-8 Dine brukere vil ikke kunne endre filtrene du låser her.
    v-card.card-simple.rounded-xl
      v-card-text.pa-8
        form-filters(v-model="filters_proxy")
        v-btn(
          :disabled="loading_locked_filters"
          :loading="loading_locked_filters"
          @click="doUpdateCompanyLockedFilters"
          color="secondary"
          x-large block depressed rounded
        ) Lagre
</template>

<script>
import { get } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { cloneDeep } from '@/lib/utils'
import { FormFilters } from '@/components/form'

export default {
  name: 'Company',
  components: { FormFilters },
  data: () => ({
    loading: false,
    loading_locked_filters: false,
    name: '',

    filters_proxy: {}
  }),
  computed: {
    ...mapGetters('App', ['isCompanyOwner']),
    ...mapState('Company', ['company'])
  },
  watch: {
    isCompanyOwner: {
      immediate: true,
      handler(v) {
        if (!v) this.$router.push('/search')
      }
    },
    company: {
      immediate: true,
      handler(v) {
        if (!v) return

        this.name = cloneDeep(v.name)
        this.filters_proxy = cloneDeep(get(v, 'locked_filters', {}))
      }
    }
  },
  methods: {
    ...mapActions('App', ['setSnackbar']),
    ...mapActions('Company', ['readCompany', 'updateCompany']),
    formatDate(d) {
      return dayjs(parseInt(d)).format('D MMM HH:mm, YYYY')
    },
    async doUpdateCompanyName() {
      this.loading = true
      try {
        await this.updateCompany({ name: this.name })
        this.setSnackbar({
          type: 'success',
          msg: 'Bedriften er nå oppdatert'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading = false
      }
    },
    async doUpdateCompanyLockedFilters() {
      this.loading_locked_filters = true
      try {
        await this.updateCompany({ locked_filters: this.filters_proxy })
        this.setSnackbar({
          type: 'success',
          msg: 'Låste filtre er nå oppdatert'
        })
      } catch (e) {
        this.setSnackbar({
          type: 'error',
          msg: e.message
        })
      } finally {
        this.loading_locked_filters = false
      }
    }
  },
  mounted() {
    try {
      this.readCompany()
    } catch (e) {
      // NOP
    }
  }
}
</script>

<style lang="stylus" scoped></style>
